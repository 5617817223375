<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="4">
        <b-card>
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <b-form-group label="Данс:">
            <b-select :options="account_list" v-model="account"></b-select>
          </b-form-group>

          <b-form-group
            id="input-group-name"
            label="Огноо:"
            label-for="input-name"
            description="Хуулга авах өдрөө оруулна уу"
          >
          <date-picker v-model="actionDate" :config="{format:'YYYY-MM-DD', useCurrent: false}" ></date-picker>
          
          </b-form-group>
          <download-excel
              class = "btn btn-primary"
              :fetch   = "fetchData"
              :fields = "download.header"
              :before-generate = "startDownload"
              :before-finish = "finishDownload"
              :name="account + '_' + actionDate + '.xls'">

                <b-spinner small type="grow" v-show="download.loading"></b-spinner> 
                <span v-show="download.loading" class="ml-1">Уншиж байна</span>
                <i class="fa fa-download" v-show="!download.loading"></i> 
                <span v-show="!download.loading" class="ml-1">Хуулга татах</span>
                
          </download-excel>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';
import moment from "moment";
export default {
  name: "KhanDownload.list",
  data: function() {
    return {
      isLoading: false,
      account: '5434077338',
      actionDate: moment(),
      account_list: [
        {
          'value': '5434077338',
          'text': 'Хаан банк - 5434077338'
        },
        {
          'value': '5034992840',
          'text': 'Хаан банк - 5034992840'
        },
        {
          'value': '8125102523',
          'text': 'Голомт банк - 8125102523'
        }
      ],
      transactions: [],
      download: {
        loading: false,
        header: {
          'Гүйлгээний огноо': 'transactionDate',
          'Салбар': 'branch',
          'Дебит гүйлгээ': 'debit',
          'Кредит гүйлгээ': 'credit',
          'Эцсийн үлдэгдэл': 'balance',
          'Гүйлгээний утга': 'description',
          'Харьцсан данс': 'relatedAccount'
        }
      },
    };
  },
  created: function() {
  },
  methods: {
    async fetchData(){
      if(this.download.loading) {
        this.download.loading = true;
        if(this.account == '') {
          this.showToast('Анхаар', 'Данс сонгоно уу', 'warning')
          this.download.loading = false;
        } else {
          let response = null;
          if(this.account == '8125102523') {
            response = await axios.post('https://service.numur.mn/bank-statement/account-statement',
            {
                "from_date": moment(this.$data.actionDate).format('YYYY-MM-DD'),
                "to_date": moment(this.$data.actionDate).format('YYYY-MM-DD'),
                "start_number": "",
                "end_number": ""
            });
            console.log(response);
          } else {
            response = await axios.get(this.$config.APP_URL_1 + 'BankWebService/get_khan_statement',{
              params: {
                account: this.$data.account,
                date: moment(this.$data.actionDate).format('YYYY-MM-DD')
              },
              headers: this.$store.getters.httpHeader,
              emulateJSON: true
            });
          }
          
          if(response.data.status == 500) {
            this.showToast('Алдаа', 'Алдаа гарсан байна. Дахин оролдоно уу', 'danger')
            this.download.loading = false;
            return []
          } 
          console.log(response);
          let _result = response.data.data
          return _result
        }
      }
      return []
    },
    startDownload(){
        this.download.loading = true;
    },
    finishDownload(){
        this.download.loading = false;
    }
  },
};
</script>
